.rli {
   display: flex;
   justify-content: space-between;
   align-items: center;
   /* border: solid #073E70 1px; */
   border: solid #dddddd 1px;
   /* border-radius: 4px; */
   height: 40px;
   width: 100%;
   /* margin-bottom: 8px; */
}

.even-rli {
   background-color: #dddddd;
}

.rli p {
   margin: 0;
   font-weight: 400;
   margin-left: 8px;
}

.rli-trash-icon {
   margin-right: 10px;
}

.rli-trash-icon:hover {
   color: #D50000;
   cursor: pointer;
}