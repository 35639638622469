.new-event-box {
   width: 520px;
   max-width: 100%;
}

div.new-event-box h4 {
   margin-bottom: 12px;
}

div.new-event-box p {
   margin: 0;
   font-size: 0.85rem;
   margin-bottom: .1rem;
   font-weight: bold;
}

div.new-event-box p.error {
   color: red;
   font-weight: 400;
   margin-top: 4px;
}

div.new-event-box .react-datepicker-wrapper {
   margin-bottom: 8px;
   font-family: arial, sans-serif;
}

div.new-event-box .date-picker-row {
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 8px;
   margin-top: 12px;
}

div.new-event-box .date-picker-row>div {
   margin-right: 20px;
}