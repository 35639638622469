.bro-input > .error-message {
    margin-left: 6px;
    color: #E60000;
}

.bro-input > label[data-bold="true"] {
    font-weight: bold;
}

.bro-input input::placeholder,
.bro-input textarea::placeholder {
   color: rgba(0, 0, 0, 0.35);
}


/*--- Modifications ---*/

.bro-input>label {
  font-size: 0.85rem;
  margin-bottom: .1rem
}






.input-group-append {
  font-family: arial, sans-serif;
}
