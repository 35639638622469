@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');

body {
   font-family: 'Raleway', sans-serif;
   margin: 0;
   padding: 0;
   word-wrap: break-word;
   color: #282B35;
   background-color: #fafafa;
}

/*TARKASTA ALLA OLEVAT*/

.main-container {
   display: flex;
   flex-direction: row;
 }
 
 .page-container {
   width: 100%;
 }
 .page-container>div {
   display: flex;
   flex-direction: row;
 }
 
 
 button:focus, a:focus{outline: none;}
 a { text-decoration: none; }
 
 
 h2 {
   font-size: 1.5rem;
   font-weight: 500;
 }
 
 h3 {
   font-size: 1.3rem;
 }
 h4 {
   font-size: 1.2rem;
 }
 h5 {
   font-size: 1.1rem;
 }
 button {
   background: none;
   border: none;
 }
 
 button:hover {
   cursor: pointer;
 }

 .no-margin {
   margin: 0;
 }
 
 .no-padding {
   padding: 0;
 }
 
 .font-bold {
   font-weight: 700;
 }
 .font-light {
   font-weight: 300;
 }

 