.add-language-button {
   background-color: #073E70;
   font-weight: 400;
   color: #fafafa;
   border-radius: 4px;
   border: none;
   height: 28px;
   width: 80px;
   font-size: 0.9rem;
}

/* div.new-lang-input {
   width: 400px;
} */

div.languagesTable {
   width: 400px;
   margin: 20px 0;
}

div.language-dropdown-container {
   width: 400px;
}
