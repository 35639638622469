@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700);
.side-menu {
  width: 240px;
  background-color: #01213D;
  height: 100vh;
  color: white;
  text-align: left;
  position: relative;
}

.side-menu h5 {
  padding-left: 21px;
  padding-right: 16px;
  font-weight: 300;
  font-size: 0.95rem;
  color: #fafafa;
}

.side-menu ul {
  padding: 0;
  text-align: left;
  color: white;
  text-decoration: none;
  list-style-type: none;
}

.side-menu a {
  color: white;
  padding-left: 18px;
  border-left-color: transparent;
  border-left-width: 3px;
  border-left-style: solid;
}
.side-menu a:hover {
  text-decoration: none;
}


.menu-item {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
}

a.menu-link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

a.active {
  background-color: #073E70;
  color: #d8e4ef;
  /* border-left-color: #d8e4ef;
  border-left-width: 3px;
  border-left-style: solid; */
}

.menu-item:hover {
  cursor: pointer;
  background-color: #073E70; /* rgba(91, 154, 213, 0.514) */
}



.sign-out-btn {
   margin-left: 20px;
   position: absolute;
   bottom: 24px;
   font-weight: 300;
   font-size: 0.92rem;
}
.sign-out-btn:hover {
   color: #D50000;
}



img.main-logo {
   width: 38px;
   margin-left: 20px;
   padding-top: 12px;
   padding-bottom: 12px;
}

div.tulkki-logo-row {
   width: 100%;
   background-color: #01213D;
   padding-bottom: 8px;
   /* margin-bottom: 20px; */
   border-bottom: solid 0.5px #fafafa80;
}

.bro-borderless-button { 
   transition: 0.175s;
   border-radius: 100px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   -webkit-user-select: none;
           user-select: none;
}
.bro-borderless-button:focus {
   outline: 0;
}
.bro-borderless-button[disabled]{ 
   pointer-events: none;
}
.bro-borderless-button[data-bold=true] { 
   font-weight: bold;
}
.bro-borderless-button > .title {
   margin-bottom: 0;
}
.bro-borderless-button[data-type=default] {
   color: rgb(90, 90, 90);
}
.bro-borderless-button[data-type=danger] {
   color: rgb(150, 0, 0);
}
.bro-borderless-button[data-type=danger][disabled] {
   color: rgb(179, 87, 87);
}

.bro-borderless-button:hover[data-type=default][data-shadow-type=none] {
   color: rgb(50, 50, 50);
}

.bro-borderless-button:hover[data-type=danger][data-shadow-type=none] {
   color: rgb(100, 0, 0);
}

.bro-borderless-button:hover[data-type=default][data-shadow-type=material] {
   box-shadow: 0px 0px 0px 8px rgba(148, 148, 148, 0.5);
   background: rgba(148, 148, 148, 0.5);
   color: rgb(80, 80, 80);
}
.bro-borderless-button:hover[data-type=danger][data-shadow-type=material] {
   box-shadow: 0px 0px 0px 8px rgba(207, 33, 33, 0.1);
   background: rgba(207, 33, 33, 0.1);
   color: rgb(207, 33, 33);
}
.bro-borderless-button:hover[data-type=default][data-shadow-type=text_shadow],
.bro-borderless-button:focus[data-type=default][data-shadow-type=text_shadow] {
   text-shadow: 0 0 3px rgb(160, 160, 160);
   -webkit-filter: drop-shadow( 0px 0px 2px rgb(160, 160, 160));
           filter: drop-shadow( 0px 0px 2px rgb(160, 160, 160));
}
.bro-borderless-button:hover[data-type=danger][data-shadow-type=text_shadow],
.bro-borderless-button:focus[data-type=danger][data-shadow-type=text_shadow] {
   text-shadow: 0 0 3px rgb(207, 33, 33);
   -webkit-filter: drop-shadow(0px 0px 2px rgb(207, 33, 33));
           filter: drop-shadow(0px 0px 2px rgb(207, 33, 33));
}


.bro-borderless-button[data-size=1] > .title {
   font-size: 1.5rem;
}
.bro-borderless-button[data-size=1] > .icon {
   font-size: 1.5rem;        
}

.bro-borderless-button[data-size=2] > .title {
   font-size: 1.17rem;    
}
.bro-borderless-button[data-size=2] > .icon {
   font-size: 1.2rem;    
}

.bro-borderless-button[data-size=3] > .title {
   font-size: 1.0rem;    
}
.bro-borderless-button[data-size=3] > .icon {
   font-size: 1rem;    
}

.bro-borderless-button[data-size=4] > .title {
   font-size: 0.85rem;
}
.bro-borderless-button[data-size=4] > .icon {
   font-size: 0.7rem;
}
.button-new-item {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #073E70;
  color: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.add-btn-fa-icon {
   font-size: 15px;
}

.button-new-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.default-cell {
  height: 36px;
  background-color: #fafafa;
  width: calc(100% - 16px);
  margin: 0 8px 4px;
  position: relative;
  border: 1.2px solid #073e70;
  border-radius: 3px;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.default-cell:hover {
  cursor: pointer;
  background-color: #e1e1e1;
}

.selected-cell {
  background-color: #073e70;
  color: #fafafa;
}
.selected-cell:hover {
  background-color: #073e70;
}

.cell-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.title-label,
.subtitle-label {
  padding-left: 12px;
}

p.title-label {
  font-size: 1rem;
}
p.subtitle-label {
  font-weight: 300;
}

.default-cell p {
  margin: 0;
}

.cell-info-box {
  display: flex;
  align-items: center;
  font-family: arial, sans-serif;
  font-size: 0.9rem;
}

.status-circle {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-right: 8px;
  margin-left: 6px;
}
.lock-icon {
  margin-right: 8px;
  font-size: 0.8rem;
}

.online {
  background-color: #00c853;
}

.offline {
  background-color: #d50000;
}

.busy {
  background-color: #ffab00;
}

/* p.date-label {
  position: absolute;
  font-weight: 300;
  top: 4px;
  right: 16px;
  font-size: 0.9rem;
} */

.list {
  max-height: calc(100vh - 255px);
  width: 100%;
  padding-top: 8px;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
}

.no-interpreter {
  color: #d50000;
  height: 36px;
  width: calc(100% - 16px);
  /*margin: 0 8px 4px;*/
  margin: -10px 0 15px;
  position: relative;
  text-align: left;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-form-input {
  height: 36px;
  background-color: #fafafa;
  width: calc(100% - 32px);
  margin: 0 8px 4px;
  /*margin-top: 60px;*/
  position: relative;
  border: 1.2px solid #073e70;
  border-radius: 3px;
  text-align: left;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-container {
  display: flex;
  flex-direction: column;
  background-color: #d8e4ef;
  width: 400px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  line-height: 1.1;
}

.list-inner-container {
   /* width: 100%;
   height: calc(100vh - 60px); */
   /* background-color: red; */
   overflow-y: auto;
}

.list-container.toggled {
   width: 60px;
 }

.list-container h2 {
  text-align: left;
  margin: 0;
  margin-left: 2px;
}

.list-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 8px;
}

.toggle-button {
   display: none;
   position: absolute;
   width: 33px;
   height: 33px;
   border-radius: 16.5px;
   background-color: #073E70;
   color: #fafafa;
   bottom: 20px;
   right: 8px;
   font-size: 14px;
   padding-left: 11px;
   padding-top: 6px;
}

.toggle-button:hover {
   cursor: pointer;
}

div.content-container {
   background-color: #dadada;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0 12px;
}

div.content-container.mobile {
   height: calc(100vh - 56px);
   padding: 0 0px;
}

div.content-container h3 {
   margin-top: 4px;
}

div.inner-c-container {
   width: 100%;
   padding: 20px;
   background-color: #eeeeee;
   border-radius: 4px;
   box-shadow:         0px 1px 8px 3px #ccc;

}

div.content-container.mobile .inner-c-container {
   padding: 4px;
}

div.outer-c-container {
   width: 100%;
   max-width: 1200px;
   padding: 16px 0;
   /* overflow: visible; EI TOIMI JOS SCROLL Y*/
   overflow-y: auto;

}

div.content-container.mobile .outer-c-container {
   padding: 4px 0;;
}

div.container-box {
  padding: 12px 20px;
  background-color: #fafafa;
  border: solid 1px #073E70;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: left;
  position: relative;

  /* max-width: 800px;
  margin-left: auto;
  margin-right: auto; */
}

div.container-box.mobile {
  padding: 6px;
}

.cog-wheel {
  position: absolute;
  right: -6px;
  top: -6px;
}

.close-button {
   position: absolute;
   right: 12px;
   top: 12px;
   font-size: 1.1rem;
}

.analytics-title-header {
   font-weight: 300;
   margin-top: 0;
}

.details-btn-row {
   width: 100%;
   display: flex;
   justify-content: flex-end;
}
.btn-show-details {
   width: 100px;
   height: 30px;
   background-color: transparent;
   border-width: 1px;
   border-radius: 15px;
   border-style: solid;
   border-color: #073E70;
   text-align: left;
   padding-left: 12px;

   display: flex;
   align-items: center;
   justify-content: space-between;
}

.fa-icon-details-btn {
   margin-right: 4px;
}
@-webkit-keyframes animateIn {
  0% {
    -webkit-transform: scale3d(1.03, 1.03, 1);
            transform: scale3d(1.03, 1.03, 1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
@keyframes animateIn {
  0% {
    -webkit-transform: scale3d(1.03, 1.03, 1);
            transform: scale3d(1.03, 1.03, 1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes animateOut {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale3d(1.03, 1.03, 1);
            transform: scale3d(1.03, 1.03, 1);
    opacity: 0;
  }
}
@keyframes animateOut {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale3d(1.03, 1.03, 1);
            transform: scale3d(1.03, 1.03, 1);
    opacity: 0;
  }
}

div.modal-window {
  color: #afafaf;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 35px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;

  -webkit-animation: 0.2s ease-out 0s 1 animateIn;

          animation: 0.2s ease-out 0s 1 animateIn;

  display: flex;
  align-items: center;
  justify-content: center;
}

div.modal-content-container {
  /* max-width: 100%;
    max-height: 100%; */
  max-width: 100vw;
  background-color: #fafafa;
  position: relative;
  border-radius: 4px;
  padding: 20px;
}

div.modal-content-container.mobile {
  padding: 8px;
}

.bro-input > .error-message {
    margin-left: 6px;
    color: #E60000;
}

.bro-input > label[data-bold="true"] {
    font-weight: bold;
}

.bro-input input::-webkit-input-placeholder, .bro-input textarea::-webkit-input-placeholder {
   color: rgba(0, 0, 0, 0.35);
}

.bro-input input::placeholder,
.bro-input textarea::placeholder {
   color: rgba(0, 0, 0, 0.35);
}


/*--- Modifications ---*/

.bro-input>label {
  font-size: 0.85rem;
  margin-bottom: .1rem
}






.input-group-append {
  font-family: arial, sans-serif;
}

.btn > input {
  display: none;
}

.btn[data-highlight-on-focus='false'] {
  outline: none !important;
  box-shadow: none !important;
}

.bro-button.round {
  border-radius: 1000px;
  padding-left: 24px;
  padding-right: 24px;
}

/* PRIMARY * /
   bro-theme.css
*/

/* PRIMARY */
.bro-button[data-type='primary'] {
  color: white;
  background-color: #073e70;
  border: none;
}
.bro-button[data-type='primary']:hover,
.bro-button[data-type='primary']:focus,
.bro-button[data-type='primary']:active {
  color: white;
  background-color: #073e70 !important;
  border-color: #073e70;
}

/* DANGER */
.bro-button[data-type='danger'] {
  color: white;
  background-color: rgb(150, 0, 0);
  border-color: rgb(150, 0, 0);
}
.bro-button[data-type='danger']:hover,
.bro-button[data-type='danger']:focus,
.bro-button[data-type='danger']:active {
  color: white;
  background-color: rgb(126, 0, 0) !important;
  border-color: rgb(126, 0, 0) !important;
}

/* SUCCESS */
.bro-button[data-type='success'] {
  color: white;
  background-color: rgb(0, 126, 0);
  border-color: rgb(0, 126, 0);
}
.bro-button[data-type='success']:hover,
.bro-button[data-type='success']:focus,
.bro-button[data-type='success']:active {
  color: white;
  background-color: rgb(28, 114, 7) !important;
  border-color: rgb(28, 114, 7) !important;
}

/* WARNING */
.bro-button[data-type='warning'] {
  color: black;
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}
.bro-button[data-type='warning']:hover,
.bro-button[data-type='warning']:focus,
.bro-button[data-type='warning']:active {
  color: black;
  background-color: rgb(230, 207, 3) !important;
  border-color: rgb(230, 207, 3) !important;
}

table.logs-table {
   color: black;
}

.log-details-button-row {

   margin-top: 30px;
   margin-right: -15px;
   margin-left: auto;
}

.error-detils-row {
   background-color: rgba(213, 0, 0, 0.301);
}
table.logs-table {
   border-collapse: collapse;
   width: 100%;
   margin-top: 8px;
   margin-bottom: 20px;
}

table.logs-table td, th {
   border: 1px solid #bbbbbb; /*EI TOIMI*/
   text-align: left;
   padding: 8px;
}


td {
   font-family: arial, sans-serif;
   font-size: 0.9rem;
}

table.logs-tabletr.log-row:nth-child(even) {
   background-color: #ececec;
}

table.logs-table tr.log-row:hover {
   background-color: #c0c0c0;;
   cursor: pointer;
}

table.logs-table tr.interpretation-failed-row {
   background-color: rgba(75, 0, 213, 0.521);
}
table.logs-table tr.interpretation-failed-row:hover {
   background-color: rgba(75, 0, 213, 0.804);
}

table.logs-table tr.timeout {
   background-color: rgba(179, 4, 4, 0.858);
}
table.logs-table tr.timeout:hover {
   background-color: rgba(179, 4, 4, 0.758);
}

table.logs-table tr.unavailable {
   background-color: rgba(158, 0, 124, 0.858);
}
table.logs-table tr.unavailable:hover {
   background-color: rgba(158, 0, 124, 0.758);
}

table.logs-table tr.error-row {
   background-color: rgba(213, 0, 0, 0.301);
}

table.logs-table tr.error-row:nth-child(even) {
   background-color: rgba(213, 0, 0, 0.404);
}


table.logs-table tr.error-row:hover {
   background-color: rgba(213, 0, 0, 0.603);
}


table.analytics-table {
   font-family: arial, sans-serif;
   border-collapse: collapse;
   width: 100%;
   margin: 20px 0;
}

td, th {
   border: 1px solid #dddddd;
   text-align: left;
   padding: 8px;
}

td {
   font-size: 0.9rem;
}

tr:nth-child(even) {
   background-color: #dddddd;
}



/*MODAL*/

.analytic-details-table {
   color: #282B35;
   min-width: 300px;
}

.modal-button-row {
   display: flex;
   align-items: flex-end;
}

.modal-button-row button.bro-button {
   margin-left: auto;
   margin-right: 0;
}

.details-button623 {
   margin-left: 8px;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.6em solid rgba(0, 0, 0, 0.1);
  border-right: 0.6em solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.6em solid rgba(0, 0, 0, 0.1);
  border-left: 0.6em solid #073E70;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



/* .loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  background: #073E70;
  background: -moz-linear-gradient(left, #073E70 10%, rgba(194,0,69, 0) 42%);
  background: -webkit-linear-gradient(left, #073E70 10%, rgba(194,0,69, 0) 42%);
  background: -o-linear-gradient(left, #073E70 10%, rgba(194,0,69, 0) 42%);
  background: -ms-linear-gradient(left, #073E70 10%, rgba(194,0,69, 0) 42%);
  background: linear-gradient(to right, #073E70 10%, rgba(194,0,69, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #073E70;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #fafafa;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */

.react-calendar__tile:not([disabled]):hover {
   background: #073E70 !important;
   color: #FFFFFF;
}

.react-calendar__tile {
   padding: 1.2rem 0.5em !important;
   font-size: 0.9rem;
}
.react-calendar__tile--active {
   background-color: #fff;
   color: #000;
}
.react-calendar__navigation {
   height: 36px;
   margin-bottom: 0.6em;
}
.react-date-picker__calendar {
   width: 300px;
}




/*Date picker*/

.react-date-picker > .react-date-picker__button {
   width: 100%;
}
.react-date-picker > .react-date-picker__button > .react-date-picker__clear-button,
.react-date-picker > .react-date-picker__button > .react-date-picker__button__input {
   display: none;
}
.react-date-picker > .react-date-picker__button > .react-date-picker__calendar-button {
   width: 136px;
   height: 33px;
   opacity: 0;
}

.react-date-picker > .react-date-picker__button {
   width: 100%;
   opacity: 0;
}

/* .react-date-picker > .react-date-picker__calendar-button > .react-date-picker__button__icon {
   display: none;
   opacity: 0;
} */

/*OMA */

.date-picker-button {
   background-color: #073E70;
   color: #fafafa;
   width: 136px;
   border-radius: 4px;
   height: 33px;
   position: relative;
}

.date-picker-button:hover {
   cursor: pointer;
}

.date-picker-btn-lbl {
   position: absolute;
   left: 12px;
   top: 5px;
}
.multi-select-dropdown {
   border-radius: .25rem;
   margin-bottom: 16px;
   /* line-height: 38px; */
}

.dropdown-container p {
   font-size: 0.85rem;
   margin-bottom: .1rem;
   font-weight: bold;
}
.rli {
   display: flex;
   justify-content: space-between;
   align-items: center;
   /* border: solid #073E70 1px; */
   border: solid #dddddd 1px;
   /* border-radius: 4px; */
   height: 40px;
   width: 100%;
   /* margin-bottom: 8px; */
}

.even-rli {
   background-color: #dddddd;
}

.rli p {
   margin: 0;
   font-weight: 400;
   margin-left: 8px;
}

.rli-trash-icon {
   margin-right: 10px;
}

.rli-trash-icon:hover {
   color: #D50000;
   cursor: pointer;
}
.new-admin-button {
   background-color: #073E70;
   font-weight: 400;
   color: #fafafa;
   border-radius: 4px;
   border: none;
   height: 28px;
   width: 80px;
   font-size: 0.9rem;
}

.new-admin-input {
   margin-bottom: 8px;
}

.placeholder-title-123 {
   margin-top: 20px;
   font-size: 0.85rem;
   margin-bottom: .1rem;
   font-weight: bold;
}
.custom-dropdown > .dropdown-menu > .dropdown-item {
   cursor: pointer;
   -webkit-user-select: none;
           user-select: none;
}

.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active {
   background: rgb(233, 233, 233);
}


.custom-dropdown .dropdown-item[data-type=danger],
.custom-dropdown .dropdown-item[data-type=danger]:hover { color: #C82333 }

.custom-dropdown .dropdown-item[data-type=danger].active,
.custom-dropdown .dropdown-item[data-type=danger]:active {
   background: #D9534F;
   color: white;
}
div.title-component {
   display: flex;
}

div.title-component-container {
   margin-bottom: 15px;
}

div.title-component-container.no-margin {
   margin-bottom: 0;
}

div.title-component-container p {
   margin-bottom: 0;
}

h3.title-component-title {
   margin-right: 20px;
}

.cog-wheel {
   display: block;
}
.dialog-container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.363)
}

.dialog {
	background-color: white;
	border-radius: 4px;
	border: 0.5px solid #e1e1e1;
	padding: 12px;
	width: 340px;
	max-width: 100%;
	color: #000;
}

.dialog p.title {
   margin-top: 6px;
   font-weight: 500;
   font-size: 1.2rem;
}

.dialog-buttons { /*button.bro-button.btn-danger*/
	display: flex;
	justify-content: flex-end;
}
.single-select-dropdown {
   border-radius: .25rem;
   margin-bottom: 16px;
   /* line-height: 38px; */
}

.dropdown-container p {
   font-size: 0.85rem;
   margin-bottom: .1rem;
   font-weight: bold;
}

p.error-message-label {
   color: #E60000;
   margin-left: 6px;
   font-size: 80%;
   font-weight: 400;
   margin-top: -12px;
   margin-bottom: 8px;
}

.error-dropdown .Select-control {
   border-color: #E60000;
}
.button-row {
   margin-top: 30px;
}

div.new-interpreter-box h3 {
   margin-bottom: 30px;
   margin-top: 8px;
}

.alert-txt {
   color: #D50000;
}
.signInForm {
    vertical-align: middle;
    background: #fcfcfc;
    padding: 96px 80px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 2px;
    -webkit-user-select: none;
            user-select: none;

    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 80%;
    max-width: 700px;
}

p.error-txt {
   color:#D50000;
   
}


.create-user-button {
   margin-top: 16px;
   margin-left: 10px;

}



@media (max-width:576px) {
    .signInForm {
        width: 100%;
        border-radius: 0px;
        box-shadow: 0 0 0;
        /* background: 0; */
        padding: 56px 40px;
    }
}

.log-out-btn {
   margin-left: 6px;
   margin-top: 12px;
}

.add-language-button {
   background-color: #073E70;
   font-weight: 400;
   color: #fafafa;
   border-radius: 4px;
   border: none;
   height: 28px;
   width: 80px;
   font-size: 0.9rem;
}

/* div.new-lang-input {
   width: 400px;
} */

div.languagesTable {
   width: 400px;
   margin: 20px 0;
}

div.language-dropdown-container {
   width: 400px;
}

.error-component {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   margin-top: 100px;
}
.toggle-btn label {
	margin-bottom: 0;
}



.tgl {
	display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
	box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
	background: none;
}
.tgl + .tgl-btn {
	outline: 0;
	display: block;
	/* width: 4em;
	height: 2em; */
	width: 38px;
	height: 19px;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
			user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
	position: relative;
	display: block;
	content: "";
	width: 50%;
	height: 100%;
}
.tgl + .tgl-btn:after {
	left: 0;
}
.tgl + .tgl-btn:before {
	display: none;
}
.tgl:checked + .tgl-btn:after {
	left: 50%;
}

.tgl-light + .tgl-btn {
	background: #989898;
	border-radius: 19px; /* === height*/
	padding: 1px;
	transition: all 0.4s ease;
}
.tgl-light + .tgl-btn:after {
	border-radius: 50%;
	background: #fff;
	transition: all 0.2s ease;
}
.tgl-light:checked + .tgl-btn {
	background: #073E70;
}

.tgl-light[disabled] + .tgl-btn {
	background: #989898;
	pointer-events: none;
}
.tgl-light[disabled] + .tgl-btn:after {
	background: #ddd;
}
div.advanced-modal {
   color: #333;
}

div.advanced-modal hr {
   margin-bottom: 4px;
}

div.advanced-modal-button-row {
   display: flex;
   justify-content: flex-end;
}

div.advanced-modal-button-row {
   margin-bottom: -8px;
   margin-right: -12px;
}

div.advanced-modal hr.title-underline {
   color: #989898;
   height: 2px;
   margin-top: 6px;
   margin-bottom: 6px;
}
.new-event-box {
   width: 520px;
   max-width: 100%;
}

div.new-event-box h4 {
   margin-bottom: 12px;
}

div.new-event-box p {
   margin: 0;
   font-size: 0.85rem;
   margin-bottom: .1rem;
   font-weight: bold;
}

div.new-event-box p.error {
   color: red;
   font-weight: 400;
   margin-top: 4px;
}

div.new-event-box .react-datepicker-wrapper {
   margin-bottom: 8px;
   font-family: arial, sans-serif;
}

div.new-event-box .date-picker-row {
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 8px;
   margin-top: 12px;
}

div.new-event-box .date-picker-row>div {
   margin-right: 20px;
}
div.event-modal {
   width: 520px;
   max-width: 100%;
   max-height: 100%;
   color: #333;
}

div.event-modal p {
   margin-bottom: 4px;
}

div.event-modal strong {
   font-size: 0.95rem;
}

div.event-modal p.cancelled {
   color: red;
}

div.event-modal h4.duration-label {
   font-family: 'arial', 'sans-serif';
   margin-bottom: 12px;
   font-weight: 400;
   font-size: 1.15rem;
}
div.interpreter-modal {
  color: #333;
}

div.interpreter-modal hr {
  margin-bottom: 4px;
}

div.interpreter-modal-button-row {
  display: flex;
  justify-content: flex-end;
}

div.interpreter-modal hr.title-underline {
  color: #989898;
  height: 2px;
  margin-top: 6px;
  margin-bottom: 6px;
}

div.interpreter-modal-button-row {
  margin-bottom: -8px;
  margin-right: -12px;
}

#calendar h2 {
  font-family: arial, sans-serif;
  font-size: 1.3rem;
}

.event-legends {
  margin-top: 8px;
}

.legend-row {
  display: flex;
  align-items: center;
}

.event-legends p {
  margin: 0;
  margin-right: 8px;
}

.event-legend {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
}

.calendar-durations {
  font-family: arial, sans-serif;
  margin-top: 24px;
}

.calendar-durations p {
  margin-bottom: 2px;
}

.loading-indicator {
  z-index: 10;
  position: absolute;
  /* top: calc(50% - 62px);
   left: calc(50% - 22px); */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.295);
}

.example-custom-input {
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  background-color: #216ba5;
  font: inherit;
  color: #fff;
}

.react-autosuggest__container {
  position: relative;
  margin-bottom: 5px;
}

.react-autosuggest__input {
  width: 280px;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 39px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.navbar {
	background-color: #01213D;
	color: #fff;
	width: 100vw;
	min-height: 56px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 101;
   border-bottom: solid rgba(255, 255, 255, 0.1) 1px;
   padding: 0;
}

.navbar .container {
   width: 100vw;
}

.navbar img.logo {
	position: absolute;
	top: 12px;
	left: 12px;
	height: 32px;
}

.toggle-menu-btn {
	position: absolute;
	right: 12px;
	height: 28px;
	padding: 6px;
	top: 10px;
	width: auto;
	background-color: transparent;
	outline: none;
	border: none;
	z-index: 2;
}

.menu-items {
   padding-top: 54px;
   width: inherit;
}

img.toggle-icon {
	max-height: 100%;
}

.toggle-menu-item {
	font-weight: 400;
	width: 100%;
	text-align: center;
}

.toggle-menu-item:hover {
	cursor: pointer;
}

.toggle-menu-item p {
	font-size: 16px;
	font-weight: 300;
	width: 100%;
	margin: 0;
   padding-bottom: 20px;
   text-align: center;
}

.toggle-menu-item.disabled p {
	opacity: 0.4;
}

.toggle-menu-item.destructive p {
   color: red;
   font-weight: bold;
}
.calendar-analytics-date-btn {
   position: relative;
   height: 26px;
   width: 26px;
   border-radius: 50%;
   background-color: #073E70;
   color: #fafafa;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
   margin-left: 10px;
   margin-bottom: 4px;
   /* display: flex;
   align-items: center;
   justify-content: center; */
   /* overflow: hidden; */
}

.calendar-analytics-date-btn p {
   margin: 0;
   font-size: 15px;
}


.calendar-analytics-date-btn:hover {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
 }
.custom-input__container {
  position: relative;
  margin-bottom: 3px;
}

.custom-input__input {
  width: 280px;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
}

.info-text {
  color: #073e70;
  font-size: 12px;
  display: block;
}

body {
   font-family: 'Raleway', sans-serif;
   margin: 0;
   padding: 0;
   word-wrap: break-word;
   color: #282B35;
   background-color: #fafafa;
}

/*TARKASTA ALLA OLEVAT*/

.main-container {
   display: flex;
   flex-direction: row;
 }
 
 .page-container {
   width: 100%;
 }
 .page-container>div {
   display: flex;
   flex-direction: row;
 }
 
 
 button:focus, a:focus{outline: none;}
 a { text-decoration: none; }
 
 
 h2 {
   font-size: 1.5rem;
   font-weight: 500;
 }
 
 h3 {
   font-size: 1.3rem;
 }
 h4 {
   font-size: 1.2rem;
 }
 h5 {
   font-size: 1.1rem;
 }
 button {
   background: none;
   border: none;
 }
 
 button:hover {
   cursor: pointer;
 }

 .no-margin {
   margin: 0;
 }
 
 .no-padding {
   padding: 0;
 }
 
 .font-bold {
   font-weight: 700;
 }
 .font-light {
   font-weight: 300;
 }

 



/*

   replace with colors:
      <color>
      <hover-color>
      <light-color>

*/


/* ----- BRO-BUTTO ----- */


.bro-button {
   color: white;
   background-color: #073E70;
   margin-right: 1rem;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
   width: 130px;
   height: 36px;
   padding: 0;
}

.bro-button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.bro-button[data-type=primary] {
   color: white;
   background-color: #073E70;
   border: none;
}

.bro-button[data-type=danger] {
   color: white;
   background-color: #C82333 !important; /* ei toimi muuten*/
   border: none;
}

/* .bro-button[data-type=primary]:hover,
.bro-button[data-type=primary]:focus,
.bro-button[data-type=primary]:active {

   color: white;
   background-color: <hover-color> !important;
   border-color: <hover-color> !important;

} */

.bro-button[data-type=secondary] {
   color: #282B35;
   background-color: white;
   border-color: #282B35;
}





/* ----- BRO-BORDERLESS-BUTTON ----- */

.bro-borderless-button[data-type=primary] {
   color: #fafafa;
}


div.bro-borderless-button[data-type=danger] {
   color: #D50000;
}
/*
.bro-borderless-button:hover[data-type=primary][data-shadow-type=material] {
   box-shadow: 0px 0px 0px 8px rgba(<light-color>, 0.1);
   box-shadow: 0px 0px 0px 8px rgba(<light-color>, 0.1);
   background: rgba(<light-color>, 0.1);
   color: <light-color>;
} */
/* .bro-borderless-button:hover[data-type=primary][data-shadow-type=text_shadow],
.bro-borderless-button:focus[data-type=primary][data-shadow-type=text_shadow] {
   text-shadow: 0 0 3px <light-color>;
   filter: drop-shadow(0px 0px 2px <light-color>);
} */


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.flex-row {
  display: flex;
  align-items: center;
}

