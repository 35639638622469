.list {
  max-height: calc(100vh - 255px);
  width: 100%;
  padding-top: 8px;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
}

.no-interpreter {
  color: #d50000;
  height: 36px;
  width: calc(100% - 16px);
  /*margin: 0 8px 4px;*/
  margin: -10px 0 15px;
  position: relative;
  text-align: left;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
