div.event-modal {
   width: 520px;
   max-width: 100%;
   max-height: 100%;
   color: #333;
}

div.event-modal p {
   margin-bottom: 4px;
}

div.event-modal strong {
   font-size: 0.95rem;
}

div.event-modal p.cancelled {
   color: red;
}

div.event-modal h4.duration-label {
   font-family: 'arial', 'sans-serif';
   margin-bottom: 12px;
   font-weight: 400;
   font-size: 1.15rem;
}