.signInForm {
    vertical-align: middle;
    background: #fcfcfc;
    padding: 96px 80px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 2px;
    user-select: none;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 700px;
}

p.error-txt {
   color:#D50000;
   
}


.create-user-button {
   margin-top: 16px;
   margin-left: 10px;

}



@media (max-width:576px) {
    .signInForm {
        width: 100%;
        border-radius: 0px;
        box-shadow: 0 0 0;
        /* background: 0; */
        padding: 56px 40px;
    }
}
