div.title-component {
   display: flex;
}

div.title-component-container {
   margin-bottom: 15px;
}

div.title-component-container.no-margin {
   margin-bottom: 0;
}

div.title-component-container p {
   margin-bottom: 0;
}

h3.title-component-title {
   margin-right: 20px;
}

.cog-wheel {
   display: block;
}