.custom-input__container {
  position: relative;
  margin-bottom: 3px;
}

.custom-input__input {
  width: 280px;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
}

.info-text {
  color: #073e70;
  font-size: 12px;
  display: block;
}
