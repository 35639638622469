.btn > input {
  display: none;
}

.btn[data-highlight-on-focus='false'] {
  outline: none !important;
  box-shadow: none !important;
}

.bro-button.round {
  border-radius: 1000px;
  padding-left: 24px;
  padding-right: 24px;
}

/* PRIMARY * /
   bro-theme.css
*/

/* PRIMARY */
.bro-button[data-type='primary'] {
  color: white;
  background-color: #073e70;
  border: none;
}
.bro-button[data-type='primary']:hover,
.bro-button[data-type='primary']:focus,
.bro-button[data-type='primary']:active {
  color: white;
  background-color: #073e70 !important;
  border-color: #073e70;
}

/* DANGER */
.bro-button[data-type='danger'] {
  color: white;
  background-color: rgb(150, 0, 0);
  border-color: rgb(150, 0, 0);
}
.bro-button[data-type='danger']:hover,
.bro-button[data-type='danger']:focus,
.bro-button[data-type='danger']:active {
  color: white;
  background-color: rgb(126, 0, 0) !important;
  border-color: rgb(126, 0, 0) !important;
}

/* SUCCESS */
.bro-button[data-type='success'] {
  color: white;
  background-color: rgb(0, 126, 0);
  border-color: rgb(0, 126, 0);
}
.bro-button[data-type='success']:hover,
.bro-button[data-type='success']:focus,
.bro-button[data-type='success']:active {
  color: white;
  background-color: rgb(28, 114, 7) !important;
  border-color: rgb(28, 114, 7) !important;
}

/* WARNING */
.bro-button[data-type='warning'] {
  color: black;
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}
.bro-button[data-type='warning']:hover,
.bro-button[data-type='warning']:focus,
.bro-button[data-type='warning']:active {
  color: black;
  background-color: rgb(230, 207, 3) !important;
  border-color: rgb(230, 207, 3) !important;
}
