.navbar {
	background-color: #01213D;
	color: #fff;
	width: 100vw;
	min-height: 56px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 101;
   border-bottom: solid rgba(255, 255, 255, 0.1) 1px;
   padding: 0;
}

.navbar .container {
   width: 100vw;
}

.navbar img.logo {
	position: absolute;
	top: 12px;
	left: 12px;
	height: 32px;
}

.toggle-menu-btn {
	position: absolute;
	right: 12px;
	height: 28px;
	padding: 6px;
	top: 10px;
	width: auto;
	background-color: transparent;
	outline: none;
	border: none;
	z-index: 2;
}

.menu-items {
   padding-top: 54px;
   width: inherit;
}

img.toggle-icon {
	max-height: 100%;
}

.toggle-menu-item {
	font-weight: 400;
	width: 100%;
	text-align: center;
}

.toggle-menu-item:hover {
	cursor: pointer;
}

.toggle-menu-item p {
	font-size: 16px;
	font-weight: 300;
	width: 100%;
	margin: 0;
   padding-bottom: 20px;
   text-align: center;
}

.toggle-menu-item.disabled p {
	opacity: 0.4;
}

.toggle-menu-item.destructive p {
   color: red;
   font-weight: bold;
}