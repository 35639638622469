body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.flex-row {
  display: flex;
  align-items: center;
}
