.calendar-analytics-date-btn {
   position: relative;
   height: 26px;
   width: 26px;
   border-radius: 50%;
   background-color: #073E70;
   color: #fafafa;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
   margin-left: 10px;
   margin-bottom: 4px;
   /* display: flex;
   align-items: center;
   justify-content: center; */
   /* overflow: hidden; */
}

.calendar-analytics-date-btn p {
   margin: 0;
   font-size: 15px;
}


.calendar-analytics-date-btn:hover {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
 }