table.logs-table {
   border-collapse: collapse;
   width: 100%;
   margin-top: 8px;
   margin-bottom: 20px;
}

table.logs-table td, th {
   border: 1px solid #bbbbbb; /*EI TOIMI*/
   text-align: left;
   padding: 8px;
}


td {
   font-family: arial, sans-serif;
   font-size: 0.9rem;
}

table.logs-tabletr.log-row:nth-child(even) {
   background-color: #ececec;
}

table.logs-table tr.log-row:hover {
   background-color: #c0c0c0;;
   cursor: pointer;
}

table.logs-table tr.interpretation-failed-row {
   background-color: rgba(75, 0, 213, 0.521);
}
table.logs-table tr.interpretation-failed-row:hover {
   background-color: rgba(75, 0, 213, 0.804);
}

table.logs-table tr.timeout {
   background-color: rgba(179, 4, 4, 0.858);
}
table.logs-table tr.timeout:hover {
   background-color: rgba(179, 4, 4, 0.758);
}

table.logs-table tr.unavailable {
   background-color: rgba(158, 0, 124, 0.858);
}
table.logs-table tr.unavailable:hover {
   background-color: rgba(158, 0, 124, 0.758);
}

table.logs-table tr.error-row {
   background-color: rgba(213, 0, 0, 0.301);
}

table.logs-table tr.error-row:nth-child(even) {
   background-color: rgba(213, 0, 0, 0.404);
}


table.logs-table tr.error-row:hover {
   background-color: rgba(213, 0, 0, 0.603);
}