.multi-select-dropdown {
   border-radius: .25rem;
   margin-bottom: 16px;
   /* line-height: 38px; */
}

.dropdown-container p {
   font-size: 0.85rem;
   margin-bottom: .1rem;
   font-weight: bold;
}