.bro-borderless-button { 
   transition: 0.175s;
   border-radius: 100px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   user-select: none;
}
.bro-borderless-button:focus {
   outline: 0;
}
.bro-borderless-button[disabled]{ 
   pointer-events: none;
}
.bro-borderless-button[data-bold=true] { 
   font-weight: bold;
}
.bro-borderless-button > .title {
   margin-bottom: 0;
}
.bro-borderless-button[data-type=default] {
   color: rgb(90, 90, 90);
}
.bro-borderless-button[data-type=danger] {
   color: rgb(150, 0, 0);
}
.bro-borderless-button[data-type=danger][disabled] {
   color: rgb(179, 87, 87);
}

.bro-borderless-button:hover[data-type=default][data-shadow-type=none] {
   color: rgb(50, 50, 50);
}

.bro-borderless-button:hover[data-type=danger][data-shadow-type=none] {
   color: rgb(100, 0, 0);
}

.bro-borderless-button:hover[data-type=default][data-shadow-type=material] {
   box-shadow: 0px 0px 0px 8px rgba(148, 148, 148, 0.5);
   background: rgba(148, 148, 148, 0.5);
   color: rgb(80, 80, 80);
}
.bro-borderless-button:hover[data-type=danger][data-shadow-type=material] {
   box-shadow: 0px 0px 0px 8px rgba(207, 33, 33, 0.1);
   background: rgba(207, 33, 33, 0.1);
   color: rgb(207, 33, 33);
}
.bro-borderless-button:hover[data-type=default][data-shadow-type=text_shadow],
.bro-borderless-button:focus[data-type=default][data-shadow-type=text_shadow] {
   text-shadow: 0 0 3px rgb(160, 160, 160);
   filter: drop-shadow( 0px 0px 2px rgb(160, 160, 160));
}
.bro-borderless-button:hover[data-type=danger][data-shadow-type=text_shadow],
.bro-borderless-button:focus[data-type=danger][data-shadow-type=text_shadow] {
   text-shadow: 0 0 3px rgb(207, 33, 33);
   filter: drop-shadow(0px 0px 2px rgb(207, 33, 33));
}


.bro-borderless-button[data-size=1] > .title {
   font-size: 1.5rem;
}
.bro-borderless-button[data-size=1] > .icon {
   font-size: 1.5rem;        
}

.bro-borderless-button[data-size=2] > .title {
   font-size: 1.17rem;    
}
.bro-borderless-button[data-size=2] > .icon {
   font-size: 1.2rem;    
}

.bro-borderless-button[data-size=3] > .title {
   font-size: 1.0rem;    
}
.bro-borderless-button[data-size=3] > .icon {
   font-size: 1rem;    
}

.bro-borderless-button[data-size=4] > .title {
   font-size: 0.85rem;
}
.bro-borderless-button[data-size=4] > .icon {
   font-size: 0.7rem;
}