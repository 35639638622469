


/*

   replace with colors:
      <color>
      <hover-color>
      <light-color>

*/


/* ----- BRO-BUTTO ----- */


.bro-button {
   color: white;
   background-color: #073E70;
   margin-right: 1rem;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
   width: 130px;
   height: 36px;
   padding: 0;
}

.bro-button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.bro-button[data-type=primary] {
   color: white;
   background-color: #073E70;
   border: none;
}

.bro-button[data-type=danger] {
   color: white;
   background-color: #C82333 !important; /* ei toimi muuten*/
   border: none;
}

/* .bro-button[data-type=primary]:hover,
.bro-button[data-type=primary]:focus,
.bro-button[data-type=primary]:active {

   color: white;
   background-color: <hover-color> !important;
   border-color: <hover-color> !important;

} */

.bro-button[data-type=secondary] {
   color: #282B35;
   background-color: white;
   border-color: #282B35;
}





/* ----- BRO-BORDERLESS-BUTTON ----- */

.bro-borderless-button[data-type=primary] {
   color: #fafafa;
}


div.bro-borderless-button[data-type=danger] {
   color: #D50000;
}
/*
.bro-borderless-button:hover[data-type=primary][data-shadow-type=material] {
   box-shadow: 0px 0px 0px 8px rgba(<light-color>, 0.1);
   box-shadow: 0px 0px 0px 8px rgba(<light-color>, 0.1);
   background: rgba(<light-color>, 0.1);
   color: <light-color>;
} */
/* .bro-borderless-button:hover[data-type=primary][data-shadow-type=text_shadow],
.bro-borderless-button:focus[data-type=primary][data-shadow-type=text_shadow] {
   text-shadow: 0 0 3px <light-color>;
   filter: drop-shadow(0px 0px 2px <light-color>);
} */

