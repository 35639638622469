.button-row {
   margin-top: 30px;
}

div.new-interpreter-box h3 {
   margin-bottom: 30px;
   margin-top: 8px;
}

.alert-txt {
   color: #D50000;
}