div.content-container {
   background-color: #dadada;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0 12px;
}

div.content-container.mobile {
   height: calc(100vh - 56px);
   padding: 0 0px;
}

div.content-container h3 {
   margin-top: 4px;
}

div.inner-c-container {
   width: 100%;
   padding: 20px;
   background-color: #eeeeee;
   border-radius: 4px;

   -moz-box-shadow:    0px 1px 8px 3px #ccc;
   -webkit-box-shadow: 0px 1px 8px 3px #ccc;
   box-shadow:         0px 1px 8px 3px #ccc;

}

div.content-container.mobile .inner-c-container {
   padding: 4px;
}

div.outer-c-container {
   width: 100%;
   max-width: 1200px;
   padding: 16px 0;
   /* overflow: visible; EI TOIMI JOS SCROLL Y*/
   overflow-y: auto;

}

div.content-container.mobile .outer-c-container {
   padding: 4px 0;;
}
