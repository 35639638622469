#calendar h2 {
  font-family: arial, sans-serif;
  font-size: 1.3rem;
}

.event-legends {
  margin-top: 8px;
}

.legend-row {
  display: flex;
  align-items: center;
}

.event-legends p {
  margin: 0;
  margin-right: 8px;
}

.event-legend {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
}

.calendar-durations {
  font-family: arial, sans-serif;
  margin-top: 24px;
}

.calendar-durations p {
  margin-bottom: 2px;
}

.loading-indicator {
  z-index: 10;
  position: absolute;
  /* top: calc(50% - 62px);
   left: calc(50% - 22px); */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.295);
}

.example-custom-input {
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  background-color: #216ba5;
  font: inherit;
  color: #fff;
}
