.search-form-input {
  height: 36px;
  background-color: #fafafa;
  width: calc(100% - 32px);
  margin: 0 8px 4px;
  /*margin-top: 60px;*/
  position: relative;
  border: 1.2px solid #073e70;
  border-radius: 3px;
  text-align: left;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
