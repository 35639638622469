.default-cell {
  height: 36px;
  background-color: #fafafa;
  width: calc(100% - 16px);
  margin: 0 8px 4px;
  position: relative;
  border: 1.2px solid #073e70;
  border-radius: 3px;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.default-cell:hover {
  cursor: pointer;
  background-color: #e1e1e1;
}

.selected-cell {
  background-color: #073e70;
  color: #fafafa;
}
.selected-cell:hover {
  background-color: #073e70;
}

.cell-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.title-label,
.subtitle-label {
  padding-left: 12px;
}

p.title-label {
  font-size: 1rem;
}
p.subtitle-label {
  font-weight: 300;
}

.default-cell p {
  margin: 0;
}

.cell-info-box {
  display: flex;
  align-items: center;
  font-family: arial, sans-serif;
  font-size: 0.9rem;
}

.status-circle {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-right: 8px;
  margin-left: 6px;
}
.lock-icon {
  margin-right: 8px;
  font-size: 0.8rem;
}

.online {
  background-color: #00c853;
}

.offline {
  background-color: #d50000;
}

.busy {
  background-color: #ffab00;
}

/* p.date-label {
  position: absolute;
  font-weight: 300;
  top: 4px;
  right: 16px;
  font-size: 0.9rem;
} */
