.list-container {
  display: flex;
  flex-direction: column;
  background-color: #d8e4ef;
  width: 400px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  line-height: 1.1;
}

.list-inner-container {
   /* width: 100%;
   height: calc(100vh - 60px); */
   /* background-color: red; */
   overflow-y: auto;
}

.list-container.toggled {
   width: 60px;
 }

.list-container h2 {
  text-align: left;
  margin: 0;
  margin-left: 2px;
}

.list-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 8px;
}

.toggle-button {
   display: none;
   position: absolute;
   width: 33px;
   height: 33px;
   border-radius: 16.5px;
   background-color: #073E70;
   color: #fafafa;
   bottom: 20px;
   right: 8px;
   font-size: 14px;
   padding-left: 11px;
   padding-top: 6px;
}

.toggle-button:hover {
   cursor: pointer;
}
