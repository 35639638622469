.custom-dropdown > .dropdown-menu > .dropdown-item {
   cursor: pointer;
   user-select: none;
}

.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active {
   background: rgb(233, 233, 233);
}


.custom-dropdown .dropdown-item[data-type=danger],
.custom-dropdown .dropdown-item[data-type=danger]:hover { color: #C82333 }

.custom-dropdown .dropdown-item[data-type=danger].active,
.custom-dropdown .dropdown-item[data-type=danger]:active {
   background: #D9534F;
   color: white;
}