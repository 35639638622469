.btn-show-details {
   width: 100px;
   height: 30px;
   background-color: transparent;
   border-width: 1px;
   border-radius: 15px;
   border-style: solid;
   border-color: #073E70;
   text-align: left;
   padding-left: 12px;

   display: flex;
   align-items: center;
   justify-content: space-between;
}

.fa-icon-details-btn {
   margin-right: 4px;
}