.react-calendar__tile:not([disabled]):hover {
   background: #073E70 !important;
   color: #FFFFFF;
}

.react-calendar__tile {
   padding: 1.2rem 0.5em !important;
   font-size: 0.9rem;
}
.react-calendar__tile--active {
   background-color: #fff;
   color: #000;
}
.react-calendar__navigation {
   height: 36px;
   margin-bottom: 0.6em;
}
.react-date-picker__calendar {
   width: 300px;
}




/*Date picker*/

.react-date-picker > .react-date-picker__button {
   width: 100%;
}
.react-date-picker > .react-date-picker__button > .react-date-picker__clear-button,
.react-date-picker > .react-date-picker__button > .react-date-picker__button__input {
   display: none;
}
.react-date-picker > .react-date-picker__button > .react-date-picker__calendar-button {
   width: 136px;
   height: 33px;
   opacity: 0;
}

.react-date-picker > .react-date-picker__button {
   width: 100%;
   opacity: 0;
}

/* .react-date-picker > .react-date-picker__calendar-button > .react-date-picker__button__icon {
   display: none;
   opacity: 0;
} */

/*OMA */

.date-picker-button {
   background-color: #073E70;
   color: #fafafa;
   width: 136px;
   border-radius: 4px;
   height: 33px;
   position: relative;
}

.date-picker-button:hover {
   cursor: pointer;
}

.date-picker-btn-lbl {
   position: absolute;
   left: 12px;
   top: 5px;
}