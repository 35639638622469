div.container-box {
  padding: 12px 20px;
  background-color: #fafafa;
  border: solid 1px #073E70;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: left;
  position: relative;

  /* max-width: 800px;
  margin-left: auto;
  margin-right: auto; */
}

div.container-box.mobile {
  padding: 6px;
}

.cog-wheel {
  position: absolute;
  right: -6px;
  top: -6px;
}

.close-button {
   position: absolute;
   right: 12px;
   top: 12px;
   font-size: 1.1rem;
}
