div.interpreter-modal {
  color: #333;
}

div.interpreter-modal hr {
  margin-bottom: 4px;
}

div.interpreter-modal-button-row {
  display: flex;
  justify-content: flex-end;
}

div.interpreter-modal hr.title-underline {
  color: #989898;
  height: 2px;
  margin-top: 6px;
  margin-bottom: 6px;
}

div.interpreter-modal-button-row {
  margin-bottom: -8px;
  margin-right: -12px;
}
