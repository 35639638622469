.new-admin-button {
   background-color: #073E70;
   font-weight: 400;
   color: #fafafa;
   border-radius: 4px;
   border: none;
   height: 28px;
   width: 80px;
   font-size: 0.9rem;
}

.new-admin-input {
   margin-bottom: 8px;
}

.placeholder-title-123 {
   margin-top: 20px;
   font-size: 0.85rem;
   margin-bottom: .1rem;
   font-weight: bold;
}