.button-new-item {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #073E70;
  color: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.add-btn-fa-icon {
   font-size: 15px;
}

.button-new-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}
