

table.analytics-table {
   font-family: arial, sans-serif;
   border-collapse: collapse;
   width: 100%;
   margin: 20px 0;
}

td, th {
   border: 1px solid #dddddd;
   text-align: left;
   padding: 8px;
}

td {
   font-size: 0.9rem;
}

tr:nth-child(even) {
   background-color: #dddddd;
}



/*MODAL*/

.analytic-details-table {
   color: #282B35;
   min-width: 300px;
}

.modal-button-row {
   display: flex;
   align-items: flex-end;
}

.modal-button-row button.bro-button {
   margin-left: auto;
   margin-right: 0;
}

.details-button623 {
   margin-left: 8px;
}