.side-menu {
  width: 240px;
  background-color: #01213D;
  height: 100vh;
  color: white;
  text-align: left;
  position: relative;
}

.side-menu h5 {
  padding-left: 21px;
  padding-right: 16px;
  font-weight: 300;
  font-size: 0.95rem;
  color: #fafafa;
}

.side-menu ul {
  padding: 0;
  text-align: left;
  color: white;
  text-decoration: none;
  list-style-type: none;
}

.side-menu a {
  color: white;
  padding-left: 18px;
  border-left-color: transparent;
  border-left-width: 3px;
  border-left-style: solid;
}
.side-menu a:hover {
  text-decoration: none;
}


.menu-item {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
}

a.menu-link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

a.active {
  background-color: #073E70;
  color: #d8e4ef;
  /* border-left-color: #d8e4ef;
  border-left-width: 3px;
  border-left-style: solid; */
}

.menu-item:hover {
  cursor: pointer;
  background-color: #073E70; /* rgba(91, 154, 213, 0.514) */
}



.sign-out-btn {
   margin-left: 20px;
   position: absolute;
   bottom: 24px;
   font-weight: 300;
   font-size: 0.92rem;
}
.sign-out-btn:hover {
   color: #D50000;
}



img.main-logo {
   width: 38px;
   margin-left: 20px;
   padding-top: 12px;
   padding-bottom: 12px;
}

div.tulkki-logo-row {
   width: 100%;
   background-color: #01213D;
   padding-bottom: 8px;
   /* margin-bottom: 20px; */
   border-bottom: solid 0.5px #fafafa80;
}
