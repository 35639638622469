@keyframes animateIn {
  0% {
    transform: scale3d(1.03, 1.03, 1);
    opacity: 0.5;
  }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
@keyframes animateOut {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    transform: scale3d(1.03, 1.03, 1);
    opacity: 0;
  }
}

div.modal-window {
  color: #afafaf;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 35px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;

  animation: 0.2s ease-out 0s 1 animateIn;

  display: flex;
  align-items: center;
  justify-content: center;
}

div.modal-content-container {
  /* max-width: 100%;
    max-height: 100%; */
  max-width: 100vw;
  background-color: #fafafa;
  position: relative;
  border-radius: 4px;
  padding: 20px;
}

div.modal-content-container.mobile {
  padding: 8px;
}
