table.logs-table {
   color: black;
}

.log-details-button-row {

   margin-top: 30px;
   margin-right: -15px;
   margin-left: auto;
}

.error-detils-row {
   background-color: rgba(213, 0, 0, 0.301);
}