div.advanced-modal {
   color: #333;
}

div.advanced-modal hr {
   margin-bottom: 4px;
}

div.advanced-modal-button-row {
   display: flex;
   justify-content: flex-end;
}

div.advanced-modal-button-row {
   margin-bottom: -8px;
   margin-right: -12px;
}

div.advanced-modal hr.title-underline {
   color: #989898;
   height: 2px;
   margin-top: 6px;
   margin-bottom: 6px;
}